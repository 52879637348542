


















import { Component, Vue, Prop } from 'vue-property-decorator';

import { WorkPlatformService } from '@WorkPlatform/services/index';

import { namespace } from 'vuex-class';
import { CommonServices } from '@WorkPlatform/common/service';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'app-item',
  components: {
    AppImage: () => import('./app-image.vue'),
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) details!: any;

  @workPlatformModule.State('recentIds') recentIds: any;

  @workPlatformModule.Action('setRecentIds') setRecentIds: any;

  @workPlatformModule.Action('setOnlineApps') setOnlineApps: any;

  clickHandler (item: any) {
    const params: any = {
      appCode: item.appCode,
      appVersion: item.version,
    };
    localStorage.setItem('appEntrance', 'platform');
    WorkPlatformService.getAppUrl(params).then((res: any) => {
      if (res.success) {
        const homePage: any = item.homepages || {};

        const token = localStorage.getItem('token');
        let query: any = {
          groupCode: '',
          pageGroupCode: '',
          pageCode: '',
          ...homePage,
          appVersion: item.version,
          isFromTask: true,
          workflowCode: item.workflowCode,
          token,
        };
        query = CommonServices.setLoginParam(query);
        const str: string = Object.entries(query)
          .map((queryArr: any[]) => {
            return queryArr.join('=');
          })
          .join('&');
        const url = `${res.data}?${str}`;

        this.setRecentApp(item.id);

        try {
          const jumpTime: number = 100;
          setTimeout(() => {
            window.location.href = url;
          }, jumpTime);
        } catch (e) {
          Vue.prototype.$errorcatcher.showMessagePopup({
            title: '错误提示！',
            message: [e, '!'],
            displayFooter: false,
            correctGuide: '请认真检查浏览器是否开启禁止新开弹窗！',
          });
        }
      }
    });
  }

  setRecentApp (id: string) {
    const ids: string | null = this.recentIds;
    let idsArr: any[] = [];
    if (Array.isArray(ids)) {
      idsArr = ids.filter((item: any) => item !== id);
    }
    idsArr.unshift(id);
    this.setRecentIds(idsArr);
  }
}
